<template>
  <sendsms />
</template>

<script>
  import sendsms from '../components/SendSMS.vue'

  export default {
    name: 'Home',

    components: {
      sendsms,
    },
  }
</script>
