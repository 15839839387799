<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <quicksms @updateMessage="updateMessage" v-bind:urlParams="urlParams" />
      </v-col>

      <v-col cols="12"> </v-col>

      <v-col cols="3">
        <v-text-field
          v-model="mobilePrefix"
          :rules="[rules.required]"
          outlined
        ></v-text-field>
      </v-col>

      <v-col cols="9" class="pl-0">
        <v-text-field
          v-model="mobileNumber"
          :rules="[rules.required, rules.mobile]"
          label="Mobile Number"
          outlined
        ></v-text-field>
      </v-col>

      <v-col cols="12">
        <v-textarea
          v-model="messageToSend"
          :rules="[rules.counter, rules.required]"
          label="Message to send"
          outlined
          counter
          maxlength="480"
        ></v-textarea>
      </v-col>

      <v-col cols="3">
        <v-btn
          class="success"
          @click="sendMessage"
          :disabled="
            messageToSend && mobileNumber && mobilePrefix && ui.sendEnabled
              ? false
              : true
          "
          x-large
        >
          <v-icon> mdi-send </v-icon>
        </v-btn>
      </v-col>

      <v-col cols="9"> </v-col>

      <v-col cols="12">
        {{ ui.status }}
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from "axios";
import quicksms from "../components/QuickSMS.vue";

let conversationApiInstance = new platformClient.ConversationsApi();

export default {
  name: "MessageForm",
  props: ["accessToken", "userEmail", "userOrg", "urlParams"],
  components: {
    quicksms,
  },
  data: () => ({
    messageToSend: "",
    mobilePrefix: "+64",
    mobileNumber: "",
    rules: {
      counter: (v) => v.length <= 480 || "Max 480 characters",
      required: (v) => !!v || "Required",
      mobile: (v) => {
        const pattern = /^[1-9][0-9]\d{6,9}$/;
        return pattern.test(v) || "Invalid Mobile Number";
      },
    },
    ui: {
      status: "",
      sendEnabled: false,
    },
  }),
  watch: {
    mobileNumber() {
      const pattern = /^[1-9][0-9]\d{6,9}$/;
      this.ui.sendEnabled = pattern.test(this.mobileNumber);
    },
  },
  methods: {
    testMethod() {
      console.log(yeah);
    },
    updateMessage(message) {
      this.messageToSend = message;
    },
    sendMessage() {
      this.ui.status = "SENDING MESSAGE";
      axios
        .post("/api/postMessage", {
          messageToSend: this.messageToSend,
          mobileNumber: this.mobilePrefix + this.mobileNumber,
          userEmail: this.userEmail,
          userOrg: this.userOrg,
          token: this.accessToken,
        })
        .then((response) => {
          console.log(response);
          this.ui.status = `Message ${response.data.conversationId} has been sent to ${response.data.toAddress}`;
          this.messageToSend = "";
          this.mobileNumber = "";
        })
        .catch((error) => {
          console.log(error.response);
          if (
            error.response.data.body.message ==
            "Bad request: An active conversation is already in progress"
          ) {
            this.ui.status =
              "Message not sent, there's already an active SMS conversation for this number";
          }
        });
    },
  },
};
</script>
