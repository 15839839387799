<template>
  <v-container v-if="libraryIds">
    <v-row>
      <v-col cols="12" v-if="libraries.length == 0 && Object.keys(urlParams).length > 0">
        loading quick SMS libraries...
      </v-col>

      <v-col v-for="(library, i) in libraries" :key="i" cols="12">
        RESPONSE LIBRARY: {{ libraries[i].entities[0].libraries[0].name }}
        <v-btn
          v-for="(response, k) in libraries[i].entities"
          :key="k"
          block
          color="green"
          @click="updateMessageText(response.texts[0].content)"
          elevation="0"
          outlined
          small
        >
          {{ response.name }}
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
let apiInstance = new platformClient.ResponseManagementApi();

export default {
  name: "QuickSMS",
  props: ["urlParams"],
  data: () => ({
    libraryIds: [],
    libraries: [],
    libraryName: [],
  }),
  created() {
    console.log("[CXLOGiQ] Quick SMS Component Loaded");

    setTimeout(() => {
      console.log(this.urlParams.library);
      this.libraryIds = this.$props.urlParams.library;

      if (this.libraryIds) {
        this.determineLibrary();
      }
    }, 1500);
  },
  methods: {
    determineLibrary() {
      if (this.libraryIds.constructor != Array) {
        this.getLibrary(this.libraryIds);
      }

      if (this.libraryIds.length > 1) {
        this.libraryIds.forEach((libraryId) => this.getLibrary(libraryId));
      }
    },
    getLibrary(libraryId) {
      console.log(`fetching responses for ${libraryId}`);
      apiInstance
        .getResponsemanagementResponses(libraryId)
        .then((library) => {
          this.libraries.push(library);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    updateMessageText(responseText) {
      console.log(responseText);
      this.$emit("updateMessage", responseText);
    },
  },
};
</script>
