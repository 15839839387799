// Disable linter for this part as it isn't aware of platformClient loaded in index.html
/* eslint-disable */
const client = platformClient.ApiClient.instance
client.setEnvironment('mypurecloud.com.au')
client.setPersistSettings(true, 'cxlogiq');

// GENESYS CLOUD IMPLICIT GRANT
const clientId = process.env.VUE_APP_CLIENT_ID;
//const redirectUri = process.env.VUE_APP_REDIRECT_URL; // now set dynamically rather than using envs

// GENESYS CLOUD API
const usersApi = new platformClient.UsersApi()
const conversationsApi = new platformClient.ConversationsApi()
const notificationsApi = new platformClient.NotificationsApi()
const presenceApi = new platformClient.PresenceApi();
const routingApi = new platformClient.RoutingApi();
const voicemailApi = new platformClient.VoicemailApi();

import sendsms from './components/SendSMS.vue'

// VUE BEGINS
export default {
    name: "App",  
    components: {
        sendsms,
      },
    data: () => ({
        redirectUri: null,
        cxlogiq: {
            appName: 'scaffold',
            appDisplayName: null,
            logging: true,
        },
        licensing: {
            mode: null,
            expires: null,
            contact: null,
            licenseDisplayString: null
        },
        licensed: false,
        urlParams: null,
        user: {
            id: null,
            name: null,
            email: null,
            division: null,
            roles: [],            
        },
        accessToken: 'yeah',
        organization: {
            id: null,
            name: null,
            domain: null,
        }
    }),
    created() {    
        this.log('######### '+ this.cxlogiq.appName + ' is Starting! #########')
        // URLPARAMS
        this.urlParams = JSON.stringify(this.$route.query);
        this.log('* URL Parameters: '+this.urlParams)
        // GENERATE REDIRECT URI
        this.redirectUri = window.location.origin
        console.log(`Using Redirect URI: ${this.redirectUri}`)
        // DEFINE CLIENT
        const client = platformClient.ApiClient.instance;
        client.setEnvironment("mypurecloud.com.au");
        client.setPersistSettings(true, 'cxlogiq');
        // INITIATE LOGIN
        this.loginImplicitGrant() 
    },
    methods: {
        // LOGGING
        log(message) {
            if (this.cxlogiq.logging) {
                console.log('[CXLOGiQ] '+message)
            }
        },
        // STARTUP
        loginImplicitGrant() {
            this.log('PureCloud ImplicitGrant requested')
            client.loginImplicitGrant(clientId, this.redirectUri, {state: this.urlParams})
            .then((data) => {  
                this.log('PureCloud ImplicitGrant success')
                console.log(JSON.parse(data.state))
                this.urlParams = JSON.parse(data.state)
                this.accessToken = data.accessToken
                this.getUsersMe()
            })
            .catch((err) => {
                this.log('PureCloud ImplicitGrant failure')
                this.log(err)
            })            
        },
        getUsersMe() {
            this.log('PureCloud getUsersMe requested')
            const opts = { 
                'expand': ["authorization","organization"],
              };
            usersApi.getUsersMe(opts)
            .then((data) => {
                this.log('PureCloud getUsersMe success')
                console.log(data)  
                this.user.id = data.id
                this.user.name = data.name  
                this.user.email = data.email 
                this.user.roles = data.authorization.roles     
                this.user.division = data.division.id
                this.organization.id = data.organization.id
                this.organization.name = data.organization.name
                this.organization.domain = data.organization.domain
                // this.phoneHome() 
            })
            .catch((err) => {
                this.log('PureCloud getUsersMe failure')
                this.log(err)
            })  
        },
        // PRODUCT VALIDATION
        async phoneHome() {
            // REFERENCE FOR VUE OBJECT USED AFTER LICENSE RESPONSE
            const that = this
            // BEGINS
            this.log('phoneHome requested')            
            try {
                // SETUP
                const phoneHomeUrl = process.env.VUE_APP_PHONEHOME_URL
                const phoneHomeKey = process.env.VUE_APP_PHONEHOME_KEY
                const phoneHomeRequestUrl = phoneHomeUrl+'?code='+phoneHomeKey
                const phoneHomeRequestBody = {
                    "orgId": this.organization.id,
                    "applicationName": this.cxlogiq.appName
                }
                // ACTION
                let licenseRequest = new XMLHttpRequest()
                licenseRequest.open('POST', phoneHomeRequestUrl, true)
                licenseRequest.setRequestHeader("content-type", "application/json");
                licenseRequest.onload = function () {    
                    that.log('phoneHome success')
                    let jsonResponse = JSON.parse(this.response)                    
                    console.log(jsonResponse)
                    that.licensing.mode = jsonResponse.license
                    that.licensing.expires = jsonResponse.expires
                    that.licensing.contact = jsonResponse.contact
                    that.cxlogiq.appDisplayName = jsonResponse.appDisplayName
                    that.checkLicenseState()
                }  
                licenseRequest.send(JSON.stringify(phoneHomeRequestBody));              
            } catch (err) {
                // handles 400,403,404, etc. but NOT 50x
                this.log('phoneHome failure')
                this.log(err) 
                this.appNotLicensed()             
            }            
        },
        checkLicenseState() {
            this.log('checkLicenseState requested')  
            try {
                // CHEcK FULLY LICENSED
                if (this.licensing.mode == 'licensed') {
                    this.log('checkLicenseState success - Licensed') 
                    this.log('LICENSED!')
                    this.licensed = true                
                }   
                // CHECK TRIAL
                const dateNow = new Date()
                const dateExpires = new Date(this.licensing.expires)
                const remainingTimeMsec = dateExpires - dateNow
                const remainingTimeDays = Math.ceil(remainingTimeMsec / (1000 * 60 * 60 * 24)); 
                if (this.licensing.mode == 'trial' && remainingTimeMsec > 0) {
                    this.log('checkLicenseState success - Trial Mode') 
                    this.log('TRIAL OK!')
                    this.licensed = true   
                    this.licensing.licenseDisplayString = 'Trial Mode. '+ remainingTimeDays + ' days remaining...'             
                } 
                // ACTIONS                
                if (this.licensed) {
                    this.appMainStart()
                } else {
                    this.log('checkLicenseState success - Unlicensed/Expired Trial') 
                    this.licensing.licenseDisplayString = 'Unlicensed. Please contact sales...'
                    this.appNotLicensed()
                }
            } catch(err) {
                this.log('checkLicenseState failed') 
                this.log(err)
                this.appNotLicensed()
            }
        },
        appNotLicensed() {
            this.log('######### '+ this.cxlogiq.appName + ' is NOT LICENSED FOR OPERATION! #########')
        },
        // APP STARTS HERE IF USER lOGGED IN SUCCESS & PRODUCT VALIDATED AS LICENSED/TRIAL MODE
        /* NOTES:
            1. this.licensed should be used as Master boolean to enable/disable Display componants

        */
        appMainStart() {
            this.log('######### '+ this.cxlogiq.appName + ' is Running! in '+ this.licensing.mode +' mode #########')
        }
    },
  };